import React from 'react'
import Video1 from '../assets/video.mp4'




const Videos = () => {
  return (
    

      <div id='resouces' className=" container mx-auto overflow-hidden relative lg:flex lg:items-center ">
          <div className=" py-12 px-6 lg:py-16 lg:px-8 z-20 w-full md:w-1/2 pl-4">
              <h2 className="font-extrabold text-black ">
                  <span class="block text-4xl md:text-5xl">
                      Want a quick rundown of the home buying process?
                  </span>
              </h2>
              <p className="text-xl mt-4">
                I've got you covered! In this video I'll give a high level overview of the entire process.
              </p>
        
          </div>
          <div className="flex w-full md:w-1/2 lg:ml-[9rem] gap-8 p-8 drop-shadow-xl shadow-black">
             <video src={Video1}  loop muted controls></video>
          </div>
      </div>

  )
}


export default Videos