import {useRef, useState} from 'react'
import emailjs from '@emailjs/browser';


const AboutMe = () => {
    const form = useRef();

    const [clearForm, setclearForm] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_n974kdw', 'template_ftqw077', form.current, 'qtzbzUrlT72as5H0u')
            .then((result) => {
                console.log(result.text);
                setclearForm(true)
            }, (error) => {
                console.log(error.text);
            });
    };
    return (

        <div id='about' className="bg-[url('https://images.unsplash.com/photo-1612293653515-a470738435ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80')]  bg-cover w-full bg-right container mx-auto ">
            <div className=' bg-white/80 pb-[4rem] py-10 md:py-[6rem] px-4'>
        <div class="container mx-auto px-4 flex flex-wrap w-full justify-around">
            <div class="flex flex-col w-full lg:w-1/2 items-center ">

                <div class=" flex flex-col sm:max-w-full md:max-w-2xl lg:w-full xl:w-full">
                            <h2 className='text-4xl font-mulish font-black'>About Me</h2>

                            <p className='pt-8 font-regular max-w-md'>Born and raised here in Oregon, I have spent my whole life exploring what this wonderful state has to offer and continue to do so to this day. In the summer I love spending time near any body of water with my doggos, Mike and Gary or playing golf with friends, clients, and business partners. In the winters I can be found cozied up by my wood stove or up at the mountain skiing or snowmobiling. Prior to my career in the mortgage industry, I worked in the construction industry learning the ins and outs of numerous different trades. I secretly love when something breaks around the house because it gives me a reason to dust off my tool bags and see if I still know how to use them.</p>


                            <p className='pt-8 font-regular max-w-md'>As for the working as your Mortgage Advisor, I believe in the value of home ownership and understand the importance of finding the right loan to achieve your goals. With a deep understanding of the many loan programs, I work diligently to understand each client's needs, and find mortgage solutions to best fit their financial goals.</p>

                            <p className='pt-8 font-regular max-w-md'>Throughout the borrowing process you can count on me to be a trusted guide through the loan process and ensure that your loan is completed thoroughly, accurately, and on time. I am backed by a dedicated team of operational experts and state-of-the-art technology designed to make the loan process easy and efficient.</p>
                            



                </div>

            </div>

            {/* Form */}

                    <div class="flex-col mt-5 sm:max-w-full md:max-w-2xl lg:w-1/2 xl:w-1/2  py-8 bg-white rounded-lg shadow-lg shadow-black px-7 md:px-8 lg:px-10">
                    <div class="self-start mb-2 text-3xl font-black  sm:text-2xl ">
                        Contact Me
                    </div>
                   
                   {/* Contact Form */}
                        {clearForm ? <div class="px-2 pt-8 md:pt-[12rem] text-center mb-2 ">
                            <h1 className='md:text-4xl text-3xl font-bold  text-prim'>Thank you for reaching out!</h1>
                            <p className='text-lg md:text-xl leading-9 pt-4'>Your message has been sent please allow 24-48 business hours for me to get back to you.</p>
                        </div>  
                   :
                        <div className='col-span-3 w-full h-auto'>
                            <div className='p-4'>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="grid gap-4 py-2 w-full">
                                        <div className="flex flex-col">
                                            <label className='text-lg font-bold py-2'>First &amp; Last Name</label>
                                            <input className='border-2 rounded-xl p-3 flex border-gray-500' type="text" name="user_name" id="" placeholder='First and last' required />
                                        </div>

                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label className=' text-lg font-bold py-2'>Email</label>
                                        <input className='border-2 rounded-xl p-3 flex border-gray-500' type="email" name="user_email" id="" placeholder='EMAIL'required/>
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label className=' text-lg font-bold py-2'>Phone Number</label>
                                        <input className='border-2 rounded-xl p-3 flex border-gray-500' type="tel" name="user_phone" id="" placeholder='(971) 867-5309' required/>
                                    </div>
                                    <div className='flex flex-col py-2'>
                                        <label className=' text-lg font-bold py-2'>How can I help you?</label>
                                        <textarea className='border-2 rounded-xl p-3 flex border-gray-500' rows='3' type="text" name="message" id="message" placeholder='MESSAGE' required/>
                                    </div>

                                    <input type="submit" value='SEND' className='w-full p-4 font-bold mt-4 shadow-xl shadow-gray-400 rounded-xl bg-prim  text-white hover:scale-105 hover:bg-second' />

                                </form>

                            </div>

                        </div>
                   }
                </div>

        </div>
</div>
</div>
    )
}

export default AboutMe